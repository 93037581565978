import React, { Component } from 'react';
import './styles/w3.css';
import './styles/App.css';

import Home from './views/Home';
import AboutMe from './views/AboutMe';
import Portfolio from './views/Portfolio';
import VirtualAssistant from './views/VirtualAssistant';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "Home",
    }
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    window.history.replaceState(this.state, null, "");
    window.addEventListener("popstate", (event) => {
      if (event.state) {
        this.setState({view: event.state.view});
      }
    });
  }

  changeView(name) {
    window.history.pushState({view: name}, null, "");
    this.setState({view: name});
  }

  render() {
    let viewName = this.state.view;
      switch (viewName) {
        case ("Home"): {
          return <Home handleViewChange={(view) => this.changeView(view)} />;
        }
        case ("Virtual Assistant"): {
          return <VirtualAssistant handleViewChange={(view) => this.changeView(view)} />;
        }
        // case ("About Me"): {
        //   return <AboutMe handleViewChange={(view) => this.changeView(view)} />;
        // }
        // case ("Portfolio"): {
        //   return <Portfolio handleViewChange={(view) => this.changeView(view)} />;
        // }
        // case (Blog): {
        //   return <Blog />;
        //   break;
        // }
        default: {
          return <Home handleViewChange={(view) => this.changeView(view)} />;
        }
      }
  }
}

export default App;
