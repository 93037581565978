import React, { Component } from 'react';
import Bot from '../resources/bot/bot';
import { substitutions } from '../resources/bot/contractions';
import { responses } from '../resources/bot/responses';

class BotInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            inputField: {}
        };
        this.updateQuery = this.updateQuery.bind(this);
        this.submit = this.submit.bind(this);
    }

    updateQuery(event) {
        this.setState({
            input: event.target.value,
            inputField: event.target,
        });
    }

    submit() {
        let field = this.state.inputField;
        field.value = '';
        this.setState({inputField: field});
        this.props.submitQuery(this.state.input)
    }

    handleEnter(event) {
        if (event.keyCode === 13) {
            this.submit();
        }
    }

    render() {
        return (
            <div className="w3-container">
                <input className="w3-input w3-sand w3-margin-bottom"
                       type="text"
                       placeholder="Your query..."
                       onChange={this.updateQuery}
                       onKeyDown={(event) => this.handleEnter(event)} />

                <button className="w3-btn w3-green w3-margin-bottom"
                        onClick={() => this.submit()}>
                    Submit
                </button>
            </div>
        );
    }
}

class BotOutput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bot: new Bot('Aria', {
                'substitutions': substitutions,
                'responseMap': responses,
                'noMatch': [
                    'Sorry, I do not understand.',
                    'I am not sure how to respond to that...',
                    'Could you try rephrasing that?',
                    'Very interesting',
                    'I have never thought about it that way',
                    'Will make a note of that',
                    'I am not sure',
                    'That is very interesting',
                    'I don\'t know how to answer that but you could always ask Google',
                ]
            })
        };
    }

    getResponse(query) {
        let response = this.state.bot.respond(query);
        return (
            <div className="w3-container">
                <span className="w3-text-green">
                    <BotResponse response={response} />
                </span>
            </div>
        );
    }

    render() {
        return (
            <div className="w3-container">
                {this.getResponse(this.props.query)}
            </div>
        );
    }
}

class BotResponse extends Component {
    constructor(props) {
        super(props);
        this.thinkingSpan = <span className="w3-text-red"><strong>Thinking . . .</strong></span>;
        this.state = {
            text: '',
            isThinking: false
        };
        // this.updateText = this.updateText.bind(this);
        this.mounted = false;
    }

    // updateText() {
    //     setTimeout(() => {
    //         this.setState({
    //             text: this.props.response
    //         });
    //     }, 3000);
    //     return this.state.text;
    // }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillReceiveProps() {
        this.setState({
            text: this.thinkingSpan,
            isThinking: true
        });
        setTimeout(() => {
            if (this.mounted) {
                this.setState({
                    text: this.props.response,
                    isThinking: false
                });
            }
        }, 2000);
    }

    render() {
        return (
            <span>
                {/* {this.updateText()} */}
                <strong>{this.state.text}</strong>
            </span>
        );
    }
}

export default class BotContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: 'Hi',
            avatarGradient: "url(#greenGradient)"
        }
        this.inputHandler = this.inputHandler.bind(this);
        this.avatarGradientHandler = this.avatarGradientHandler.bind(this);
    }

    inputHandler(input) {
        this.setState({query: input});
    }

    avatarGradientHandler(isThinking) {
        if (isThinking) {
            this.setState({avatarGradient: "url(#yellowGradient)"});
        }
        else {
            this.setState({avatarGradient: "url(#greenGradient)"});
        }
    }

    render() {
        return (
            <div>
                <svg height="200" width="200">
                    <defs>
                        <radialGradient id="greenGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="0%" style={{"stopColor": "rgb(255, 255, 255)", "stopOpacity": "0"}} />
                            <stop offset="100%" style={{"stopColor": "rgb(0, 255, 0)", "stopOpacity": "1"}} />
                        </radialGradient>
                        <radialGradient id="yellowGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="0%" style={{"stopColor": "rgb(255, 255, 255)", "stopOpacity": "0"}} />
                            <stop offset="100%" style={{"stopColor": "rgb(125, 125, 0)", "stopOpacity": "1"}} />
                        </radialGradient>
                    </defs>
                    <circle cx="100" cy="100" r="80" fill={this.state.avatarGradient} />
                    <ellipse cx="65" cy="65" rx="10" ry="20" />
                    <ellipse cx="135" cy="65" rx="10" ry="20" />
                    <path d="M 65 120 Q 100 200 135 120 Z" stroke="black" strokeWidth="5" fill="none" />
                </svg>
                <BotInput submitQuery={(input) => this.inputHandler(input)} />
                <BotOutput query={this.state.query} thinkingState={(isThinking) => this.avatarGradientHandler(isThinking)}/>
            </div>
        );
    }
}