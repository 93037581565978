import React, { Component } from 'react';
import fetch from 'unfetch';

export default class BlogPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // this.setTimeout = window.setTimeout.bind(this);
        this.mounted = false;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        // let xhttp = new XMLHttpRequest();
        // let numPosts = this.props.posts;
        // let i = 0;

        // xhttp.onreadystatechange = () => {
        //     console.log([this.readyState, this.status])
        //     if (this.readyState === 4) {
        //         if (this.status === 200) {
        //             let blogPosts = [];
        //             let jsonResponse = {items: JSON.parse(this.responseText)};
        //             while (i < numPosts) {
        //                 blogPosts.push(jsonResponse.items[i]);
        //                 i++;
        //             }
        //             this.setState({
        //                 posts: blogPosts
        //             });
        //         }
        //         else {
        //             console.log("Can't issue a request");
        //         }
        //     }
        //     else {
        //         console.log("Can't connect");
        //     }
        // };
        // // xhttp.open("GET", "http://blog.akashiclabs.co.za/?rest_route=/wp/v2/posts", true);
        // xhttp.open("GET", this.props.json, true);
        // xhttp.send();

        // let blogPosts = [];
        fetch(this.props.json, {
            mode: "cors", 
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                // 'Access-Control-Allow-Origin': '*'
            }
        })
            .then(result => result.json())
            .then((result) => {
                // while (i < numPosts) {
                //     blogPosts.push(result)
                //     i++;
                // }
                if (this.mounted) {
                    this.setState({posts: result});
                }
            },
            (error) => {
                console.log("ERROR");
            });
    }

    render() {
        if (this.state.posts) {
            let postElements = this.state.posts.map((post, index) =>
                // <div key={index} className="w3-card w3-sand">
                //     <p><a className="blog-link" target="_blank" href={post.link}>{post.title.rendered}</a></p>
                // </div>
                <a key={index} className="blog-link" href={post.link} target="_blank" rel="noopener noreferrer">
                    <div className="w3-container w3-padding-16 w3-card opaque-green w3-margin-top">{post.title.rendered}</div>
                </a>
            );
            return (
                <div className="w3-margin-bottom">
                    {postElements.slice(0, this.props.posts)}
                </div>
            );
        }
        else {
            return (
                <div>
                    Loading posts...
                </div>
            );
        }
    }
}