import React, { Component } from 'react';
import CentralImage from '../components/CentralImage';
import SideBarContainer from '../components/SideBarContainer';
import BlogPosts from '../components/BlogPost';
import Header from '../components/Header';
import mainImage from '../resources/images/alabs.png';

import BotContainer from '../components/BotContainer';

export default class VirtualAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <div className="w3-row"><Header /></div>
        <div className="w3-row">
          <div className="w3-quarter w3-container w3-margin-top">
            <SideBarContainer handleViewChange={this.props.handleViewChange} />
          </div>
          <div className="w3-container w3-half w3-animate-right">
            <div id="central-image-container" className="w3-container w3-center">
              {/* <CentralImage source={mainImage} alt="Image" className="alabs-image w3-round w3-margin-top" /> */}
              <p>Welcome! Please use the navigation bar on the left to navigate around the website and to go to external resources.</p>

              <p>You can also make use of the virtual assistant below, by typing your queries into the text field.</p>
              <BotContainer />
            </div>
          </div>

          <div id="blog-posts-container" className="w3-quarter w3-container">
            <div id="posts-heading" className="w3-center w3-margin-top w3-myfont">Latest Blog Posts</div>
                <BlogPosts posts={3} json={"http://blog.akashiclabs.co.za/?rest_route=/wp/v2/posts"} />
          </div>
        </div>
      </div>
    );
  }
}