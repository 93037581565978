import React, { Component } from 'react';

export default class CentralImage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="w3-container">
                <img id="profile-picture" src={this.props.source} className={this.props.className} alt={this.props.alt}/>
            </div>
        );
    }
}