function randomElement(array) {
  return array[Math.floor(Math.random() * array.length)];
}

function stripPunctuation(string) {
  var pRegex = /(~|`|!|@|#|$|%|^|&|\*|\(|\)|{|}|\[|\]|;|:|\"|'|<|,|\.|>|\?|\/|\\|\||-|_|\+|=)/g;
  return string.replace(pRegex, '');
}

// Bot class. The parameters are:
//     substitutions: an object mapping words to what they translate to
//     responseMap: an object that is a map of queries to an array of responses
//     noMatch: maps onto an array of responses when there is no input to match

export default function Bot(name, parameters) {
  var self = this;
  self.name = name;
  self.parameters = parameters;

  self.queries = [];
  self.responses = [];

  // Convert to lowercase, remove punctuation, convert common
  // contractions to full phrases
  self.sanitiseQuery = function(query) {
    query = query.toLowerCase();
    query = stripPunctuation(query);
    var queryWords = query.split(' ');
    var cleanQueryWords = [];

    queryWords.forEach(function(i) {
      var contractionCheck = self.parameters.substitutions[i];
      if (contractionCheck) {
        cleanQueryWords.push(contractionCheck);
      }
      else {
        cleanQueryWords.push(i);
      }
    });
    var cleanQuery = cleanQueryWords.join(' ').trim();
    return cleanQuery;
  }

  self.respond = function(query) {
    self.query = query;
    self.queries.push(query);

    query = self.sanitiseQuery(query);
    var response = self.parameters.responseMap[query];
    if (response) {
      self.response = response;
    }
    else {
      self.response = self.parameters['noMatch'];
    }
    self.responses.push(self.response);
    return randomElement(self.response);
  }
}
