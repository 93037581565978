import React, { Component } from 'react';
import externalLink from  '../resources/icons/external-link.png';

class NavButton extends Component {
    constructor(props) {
        super(props);
        this.currentViewActive = this.currentViewActive.bind(this);
    }

    // Children prop used to render an additional element for links that are external
    render() {
        let buttonRenderedMatchesCurrentView = this.currentViewActive(this.props.text);
        return (
            <span
                className={`w3-button w3-padding-16 w3-bar-item w3-hover-green nav-button ${buttonRenderedMatchesCurrentView}`}
                onClick={this.props.onClick}>
                {this.props.text} {this.props.children}
            </span>
        );
    }

    currentViewActive(viewName) {
        if (viewName === window.history.state.view) {
            return "w3-green"
        }
        else {
            return "";
        }
    }
}

export default class NavButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getButtonContent(button) {
        if (button.external) {
            return (<span><img id="ext-link-icon" src={externalLink} alt="External Link" /></span>); 
        }
        else {
            return "";
        }
    }

    componentDidMount() {
        let buttonNames = this.props.buttons;
        this.setState({
            navButtons: buttonNames.map((button, index) => {
                return (
                    <NavButton
                        key={index}
                        text={button.name}
                        onClick={button.onPress}>
                            {this.getButtonContent(button)}
                    </NavButton>
                );
            })
        });
    }

    render() {
        return (
            <div>
                {this.state.navButtons}
            </div>
        );
    }
}