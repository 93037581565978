import React, { Component } from 'react';
import SideBarContainer from '../components/SideBarContainer';
import Header from '../components/Header';
import PortfItems from '../components/PortfItem';

import angelaImage from '../resources/images/portfolio/angela.jpg';
import leslieImage from '../resources/images/portfolio/leslie.jpg';

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pItemsRow1: [
        {
          header: 'Leslie\'s Photography',
          footer: 'Concept for a photography website',
          image: leslieImage,
          alt: 'Leslie\'s Photography',
          link: 'https://lunararcanus.github.io/leslie-photo'
        },
        {
          header: 'Angela\'s Food Blog',
          footer: 'Concept for a cuisine blogging website',
          image: angelaImage,
          alt: 'Angela\'s Food Blog',
          link: 'https://lunararcanus.github.io/food-blog'
        }
      ]
    };
  }

  render() {
    return (
      <div className="App">
        <div className="w3-row"><Header /></div>
        <div className="w3-row">
          <div className="w3-container">
            <SideBarContainer handleViewChange={this.props.handleViewChange} />
          </div>
          <div className="w3-container w3-twothird w3-animate-zoom w3-margin-bottom">
            <div className="w3-row">
              <div className="w3-center w3-opacity portfolio-heading">Concept Websites</div>
              <PortfItems items={this.state.pItemsRow1} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}