export var responses = {
  "hello": [
    "Hi there!",
    "Hey",
    "Hi! Nice to meet you."
  ],

  "": [
    "You haven't said anything"
  ],

  "bye": [
    "Bye for now."
  ],

  "do not do it": [
    "Ok, I will not do it"
  ],

  "date": [
    "The local date and time is " + Date()
  ],

  "what is todays date": [
    "The local date and time is " + Date()
  ],

  "what is the date": [
    "The local date and time is " + Date()
  ],

  "what is your name": [
    "My name is Aria."
  ],

  "who are you": [
    "I am Aria."
  ],

  "how are you": [
    "I am doing very well, thanks",
    "Good, thanks!",
    "I am doing well - and yourself?"
  ],

  "how do i contact eynar": [
    "Eynar's cell and WhatsApp number is +27 64 155 9160 and his email is eynaroshev@gmail.com"
  ]
};
