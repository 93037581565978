import React, { Component } from 'react';

class PortfItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="portfolio-item w3-container w3-margin-top w3-third w3-margin-right">
                <div className="w3-card-4">

                    <header className="w3-container w3-sand w3-center">
                        <h1 className="portfolio-header">{this.props.header}</h1>
                    </header>

                    <div className="w3-container w3-sand">
                        <img className="portfolio-image" src={this.props.source} alt={this.props.alt} />
                    </div>

                    <footer className="w3-container w3-sand">
                        <h5 className="portfolio-footer">{this.props.footer}</h5>
                    </footer>

                </div>
            </div>
        );
    }
}

export default class PortfItems extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let items = this.props.items.map((item, index) =>
            <a key={index} target="_blank" href={item.link} rel="noopener noreferrer">
                <PortfItem
                    header={item.header}
                    footer={item.footer}
                    source={item.image}
                    alt={item.alt}
                />
            </a>
        );
        this.setState({pItems: items});
    }

    render() {
        return (
            <div>
                {this.state.pItems}
            </div>
        );
    }
}