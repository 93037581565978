export var substitutions = {
  "dont": "do not",
  "cant": "cannot",
  "wouldnt": "would not",
  "whats": "what is",
  "hey": "hello",
  "hi": "hello",
  "time": "date",
  "u": "you",
  "ur": "your"
};