import React, { Component } from 'react';
import CentralImage from '../components/CentralImage';
import SideBarContainer from '../components/SideBarContainer';
import BlogPosts from '../components/BlogPost';
import Header from '../components/Header';
import mainImage from '../resources/images/0.jpg';
import reactIcon from '../resources/icons/128px-React-icon.svg.png';
import linkedInImage from "../resources/images/self-LI.jpeg";

export default class Home extends Component {
  // The center image gets fetched from LinkedIn
  linkedInImageURL = "../resources/images/self-LI.jpeg";

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <div className="w3-row"><Header /></div>
        <div className="w3-row">
          <div className="w3-quarter w3-container w3-margin-top">
            <SideBarContainer handleViewChange={this.props.handleViewChange} />
          </div>
          <div className="w3-container w3-half w3-animate-bottom">
            <div id="central-image-container" className="w3-container w3-center w3-margin-top">
              <CentralImage source={linkedInImage} alt="Image" className="w3-circle w3-margin-top w3-margin-bottom" />
              <p>I am a creative from South Africa working in <span className="span-steam w3-text-green">STEAM</span><span className="w3-text-green"> (Science, Technology, Engineering, Art, Mathematics)</span>. I am interested in a range of subjects such as data analysis and databases, web development, visual &amp; sound design, as well as software engineering processes and I enjoy collaborating 💬 with others.</p>

              <p>In the sidebar, you can find links to various topics of interest, such as my blog, GitHub and my LinkedIn profile.</p>

              <p>Feel free to get in touch with me at any time - my contact details are on LinkedIn! Alternatively, ask the virtual assistant, "<span className="w3-text-green">How do I contact Eynar?</span>"</p>

              <p>This website was built utilising ReactJS.<img id="react-icon" src={reactIcon} alt="React" /></p>
            </div>
          </div>

          <div id="blog-posts-container" className="w3-container w3-quarter">
            <div id="posts-heading" className="w3-center w3-margin-top w3-myfont">Latest Blog Posts</div>
            <BlogPosts posts={3} json={"http://blog.akashiclabs.co.za/?rest_route=/wp/v2/posts"} />
          </div>
        </div>
      </div>
    );
  }
}